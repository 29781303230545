import React from 'react';
import { IconButton, Button, VStack, Spacer } from '@chakra-ui/react';
import { FaSortAmountDown, FaSortAlphaDown, FaSortNumericDown, FaSortNumericDownAlt } from 'react-icons/fa'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react';

export const SortButton = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSortClick = (strategy) => {
    // Call the callback with the selected sorting strategy
    props.onSortChange(strategy);
    onClose();
  };

  return (
    <>
      <IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={onOpen}
      icon={<FaSortAmountDown />}
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>Sort countries by...</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="flex-start">
              <Button leftIcon={<FaSortAlphaDown />} colorScheme='green' variant='solid' onClick={() => handleSortClick('sortByName')}>
                Country name
              </Button>
              <Spacer/>
              <Button leftIcon={<FaSortNumericDown />} colorScheme='green' variant='solid' onClick={() => handleSortClick('sortByAreaAsc')}>
                Area ascending
              </Button>
              <Button leftIcon={<FaSortNumericDownAlt />} colorScheme='green' variant='solid' onClick={() => handleSortClick('sortByAreaDesc')}>
                Area descending
              </Button>
              <Spacer/>
              <Button leftIcon={<FaSortNumericDown />} colorScheme='green' variant='solid' onClick={() => handleSortClick('sortByPopAsc')}>
                Population ascending
              </Button>
              <Button leftIcon={<FaSortNumericDownAlt />} colorScheme='green' variant='solid' onClick={() => handleSortClick('sortByPopDesc')}>
                Population descending
              </Button>
            </VStack>
            
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
