import { Card, CardHeader, CardBody, Tooltip, CardFooter, Text, Flex, Image, VStack, Container, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import * as React from 'react'
import { useState, useEffect } from 'react';
import { Search2Icon } from '@chakra-ui/icons'

export const CountryCard = ({ sortStrategy }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect( () => {
        fetch("https://restcountries.com/v3.1/all?fields=name,capital,population,region,subregion,area")
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setItems(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }, [])

    const [filteredList, setFilteredList] = new useState(items);

    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
        // Create copy of item list
        var updatedList = [...items];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            return item.name.common.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                item.region.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                item.subregion.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                (item.capital[0]?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                item.capital[0] !== undefined);
        });

        const sortedCountries = applySorting(updatedList, sortStrategy);
        // Trigger render with updated values
        setFilteredList(sortedCountries);
    };

    useEffect(() => {
        // This effect will run whenever sortStrategy changes
        // It will apply the sorting to the filtered list of countries
        console.log("useeffect: "+ sortStrategy);
        const sortedCountries = applySorting([...filteredList], sortStrategy);
        setFilteredList(sortedCountries);
      }, [sortStrategy]); // Add sortStrategy as a dependency

    function applySorting(list, sortStrategy){
        if (sortStrategy === 'sortByName') {
            return list.sort((a, b) => a.name.common.localeCompare(b.name.common));
        } else if (sortStrategy === 'sortByAreaAsc') {
            return list.sort((a, b) => a.area - b.area);
        } else if (sortStrategy === 'sortByAreaDesc') {
            return list.sort((a, b) => b.area - a.area);
        } else if (sortStrategy === 'sortByPopAsc') {
            return list.sort((a, b) => a.population - b.population);
        } else {
            return list.sort((a, b) => b.population - a.population);
        }
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return (
            <VStack>
                <Text fontSize="3xl">Loading...</Text>
            </VStack>
        );
    }else if (filteredList.length === 0) {
        return (
            <VStack>
                <Container centerContent>
                    <Tooltip label='Start typing here...' defaultIsOpen hasArrow arrowSize={15} closeDelay={1000}>
                        <InputGroup justifyItems="center" ml="auto" mr="auto">
                            <InputLeftElement pointerEvents='none'>
                                <Search2Icon color='gray.300' />
                            </InputLeftElement>
                            <Input maxW="xl" type='search' onChange={filterBySearch} placeholder='Country names, capitals and subregions...' />
                        </InputGroup>
                    </Tooltip>
                </Container>
                <Image h="72" w="96" src='globe-44.gif'/>
            </VStack>
        );
    } else {
        return(
            <VStack>
                <Container centerContent>
                    <Tooltip label='Start typing here...' defaultIsOpen hasArrow arrowSize={15} closeDelay={1000}>
                        <InputGroup justifyItems="center" ml="auto" mr="auto">
                            <InputLeftElement pointerEvents='none'>
                                <Search2Icon color='gray.300' />
                            </InputLeftElement>
                            <Input maxW="xl" type='search' onChange={filterBySearch} placeholder='Country names, capitals and subregions...' />
                        </InputGroup>
                    </Tooltip>
                </Container>
                <Text>{filteredList.length} results</Text>
                <VStack spacing={8} pt="8">
                    <Flex wrap="wrap" justify="space-around">
                        {filteredList.map(item => (
                            <Card w="72" m="3" borderColor="darksalmon" borderWidth="medium" backgroundColor="green.300" borderRadius="2xl" key={item.name.common}>
                                <CardHeader>
                                    <Text size="lg" fontWeight="bold">{item.name.common}</Text>
                                </CardHeader>
                                <CardBody>
                                    <Text fontSize="md" align="left"><strong>Region:</strong> {item.region}</Text>
                                    <Text fontSize="md" align="left"><strong>Subregion:</strong> {item.subregion}</Text>
                                    <Text fontSize="md" align="left"><strong>Capital:</strong> {item.capital}</Text>
                                    <Text fontSize="md" align="left"><strong>Population:</strong> {item.population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} people</Text>
                                    <Text fontSize="md" align="left"><strong>Area:</strong> {item.area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} km<sup>2</sup></Text>
                                </CardBody>
                            </Card>
                        ))}
                    </Flex>
                </VStack>
            </VStack>
        );
    }
}
