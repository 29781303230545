import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { CountryCard } from './Components/CountryCard.jsx'
import { SortButton } from './Components/SortButton.jsx'
import { useState } from 'react';

function App() {
  const [sortStrategy, setSortStrategy] = useState(null);

  const handleSortChange = (strategy) => {
    // Update the selected sorting strategy when a button is clicked
    setSortStrategy(strategy);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <Flex pt="1.5" pb="4" justifyContent="space-between">
            <SortButton onSortChange={handleSortChange}/>
            <Heading>Explore the Globe!</Heading>
            <ColorModeSwitcher justifySelf="flex-end" />
          </Flex>
          <CountryCard sortStrategy={sortStrategy} />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
